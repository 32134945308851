import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import posts_data from '../../content.json';
import { useTheme } from '../../themeContext';
import './Posts.css';
import { t } from 'i18next';

const groupPostsByYear = (posts) => {
    return posts.reduce((groups, post) => {
        const year = new Date(post.date).getFullYear();
        if (!groups[year]) {
            groups[year] = [];
        }
        groups[year].push(post);
        return groups;
    }, {});
};

const Posts = ({ type, lang }) => {
    const { theme } = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedCategory, setSelectedCategory] = useState('All');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownWidth, setDropdownWidth] = useState('auto');
    const dropdownRef = useRef(null);
    const hiddenRef = useRef(null);

    const today = useMemo(() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }, []);

    // Obtener todas las categorías únicas
    const allCategories = useMemo(() => [
        t('All'),
        ...new Set(
            posts_data[type]
                .filter(post => post.lang === lang || post.lang === 'global')
                .filter(post => post.active===undefined || post.active === true)
                .filter(post => new Date(post.date) <= today)
                .flatMap(post => post.categories)
        ),
    ], [type, lang, today]);

    // Manejar inicialización basada en el parámetro de la URL
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const categoryFromUrl = searchParams.get('category');

        // Validar que el parámetro de categoría esté dentro de las opciones válidas
        if (categoryFromUrl && allCategories.includes(categoryFromUrl)) {
            setSelectedCategory(categoryFromUrl);
        } else {
            setSelectedCategory('All'); // Por defecto, "All"
        }
    }, [location.search, allCategories]);

    // Calcular el ancho dinámico basado en el texto más largo
    useEffect(() => {
        if (hiddenRef.current) {
            setDropdownWidth(`${hiddenRef.current.offsetWidth + 30}px`);
        }
    }, [allCategories]);

    // Manejar clics fuera del dropdown
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    // Manejar cambio de categoría y actualización del parámetro en la URL
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setDropdownOpen(false);

        // Actualizar el parámetro en la URL
        const searchParams = new URLSearchParams(location.search);
        if (category === t('All')) {
            searchParams.delete('category');
        } else {
            searchParams.set('category', category);
        }
        navigate({ search: searchParams.toString() });
    };


    const filteredPosts = posts_data[type].filter(
        post =>
            (post.lang === lang || post.lang === 'global') &&
            (selectedCategory === 'All' || post.categories.includes(selectedCategory)) &&
            (post.active===undefined || post.active === true) &&
            (new Date(post.date) <= today) 
    );

    const groupedPosts = groupPostsByYear(filteredPosts);

    return (
        <>
            <div className={`bg ${theme}`}></div>
            <div className={`content ${theme}`}>
                <div className='posts container'>
                    <h1 style={{ textTransform: 'capitalize' }}>{type}</h1>
                    <hr></hr>

                    {allCategories.length > 1 && (
                        <>
                            {/* Dropdown Personalizado */}
                            <div className="custom-dropdown" ref={dropdownRef}>
                                {/* Texto para calcular el ancho dinámico */}
                                <div
                                    ref={hiddenRef}
                                    style={{
                                        position: 'absolute',
                                        visibility: 'hidden',
                                        whiteSpace: 'nowrap',
                                        height: 'auto',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {allCategories.reduce((a, b) => (a.length > b.length ? a : b))}
                                </div>


                                {t('category')}: <button
                                    className="dropdown-button"
                                    onClick={() => setDropdownOpen(!dropdownOpen)}
                                    style={{ width: dropdownWidth }}
                                >
                                    {selectedCategory === 'All' ? t('All') : selectedCategory}
                                </button>
                                {dropdownOpen && (
                                    <ul
                                        className="dropdown-options"
                                        style={{ width: dropdownWidth }}
                                    >
                                        {allCategories.map(category => (
                                            <li
                                                key={category}
                                                className="option"
                                                onClick={() => handleCategoryChange(category)}
                                            >
                                                {category}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </>)}

                    {Object.keys(groupedPosts).length > 0 ? (
                        <ul style={{ listStyle: 'none' }}>
                            {Object.keys(groupedPosts).sort((a, b) => b - a).map(year => (
                                <li key={year}>
                                    <h2>{year}</h2>
                                    <ul style={{ listStyle: 'none' }}>
                                        {groupedPosts[year].map(post => (
                                            <li key={post.slug} className='post-entry'>
                                                <Link to={`/${type}/${post.slug}`}>
                                                    <h3>{`${String(new Date(post.date).getDate()).padStart(2, '0')}/${String(new Date(post.date).getMonth() + 1).padStart(2, '0')}`} | {post.title}</h3>
                                                    <p>
                                                        {post.description}
                                                        {post.categories.length > 0 && (
                                                            <>
                                                                <hr style={{ border: 'none' }} />
                                                                <small>{t('categories')}: {post.categories.join(', ')}</small>
                                                            </>
                                                        )}
                                                    </p>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div style={{ fontSize: '64px' }}>No posts available :(</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Posts;
