import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import hljs from 'highlight.js';
import mermaid from 'mermaid';
import 'highlight.js/styles/github.css';
import './MarkdownReader.css';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../themeContext';
import katex from 'katex';
import { FaFolderOpen } from "react-icons/fa";

// Función para extraer el front-matter del markdown
const extractFrontMatter = (text) => {
    const frontMatterMatcher = /^---\s*[\r\n]([\s\S]+?)\s*---/;
    const titleMatcher = /^title:\s*(.+)$/m;
    const dateMatcher = /^date:\s*(.+)$/m;
    const modMatcher = /^modification:\s*(.+)$/m;
    const categoriesMatcher = /^categories:\s*[[]]?\s*(.+)$/m;
    const frontMatterMatch = text.match(frontMatterMatcher);
    let contentWithoutFrontMatter = text;
    let title = '';
    let date = '';
    let mod = '';
    let categories = [];

    if (frontMatterMatch) {
        const frontMatterContent = frontMatterMatch[1];
        const titleMatch = frontMatterContent.match(titleMatcher);
        const dateMatch = frontMatterContent.match(dateMatcher);
        const modMatch = frontMatterContent.match(modMatcher);
        const categoriesMatch = frontMatterContent.match(categoriesMatcher);
        contentWithoutFrontMatter = text.slice(frontMatterMatch[0].length);

        if (titleMatch) title = titleMatch[1].trim();
        if (dateMatch) date = dateMatch[1].trim();
        if (modMatch) mod = modMatch[1].trim();
        if (categoriesMatch) categories = categoriesMatch[1].replace(/'/g, "").replace("]", "").trim().split(/, */);
    }

    return { contentWithoutFrontMatter, title, date, mod, categories };
};

// Función para inicializar Mermaid
const initializeMermaid = (theme) => {
    mermaid.initialize({
        startOnLoad: true,
        theme: theme === 'light' ? 'neutral' : 'dark', // `forest`, `dark`, `neutral`, `base` etc.
        themeVariables: {
            fontSize: '12px',
            nodeSpacing: 50,
            edgeLength: 50,
            nodeWidth: 150,
            nodeHeight: 30,
        },
        flowchart: {
            useMaxWidth: false,
            htmlLabels: true,
        },
    });
};

// Función para ajustar el tamaño del gráfico de Mermaid
const resizeMermaidGraphs = () => {
    const svgElements = document.querySelectorAll('.mermaid svg');
    svgElements.forEach(svg => {
        const bbox = svg.getBBox();
        svg.setAttribute('viewBox', `0 0 ${bbox.width + 10} ${bbox.height + 50}`);
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', `${bbox.height + 50}`);
    });
};

// Función para añadir números de línea a los bloques de código
const addLineNumbers = (html, t) => {
    const codeBlockRegex = /<pre><code([^>]*)>([\s\S]*?)<\/code><\/pre>/g;
    const matches = [...html.matchAll(codeBlockRegex)];

    matches.forEach((match) => {
        const [fullMatch, attrs, code] = match;
        const lines = code.split('\n');
        if (lines[lines.length - 1] === '') lines.pop();

        const languageMatch = attrs.match(/class="([^"]*language-([^"\s]+)[^"]*)"/);
        const language = languageMatch ? languageMatch[2] : 'plaintext';

        if (language === 'plain') {
            return
        }

        let nextLine = null;
        const newCodeLines = lines.map((line, idx) => {
            if (language === 'assembly' || language === 'x86') {
                // Debido a un BUG en highlight.js, las líneas que vacias en assembly
                // Son igual '<span class="hljs-symbol">' pero sin el cierre </span>
                // Asi que hay que manejar estos casos

                // La anterior linea es '<span class="hljs-symbol">'
                if (nextLine) {
                    line = nextLine + line + "</span>";
                    nextLine = null; 
                }
                
                // Si la línea es exactamente '<span class="hljs-symbol">'
                if (line.trim() === '<span class="hljs-symbol">') {
                    nextLine = line; 
                    line = ""; 
                }

            }
            return `<span class="line-number">${idx + 1}</span> <span class="code-line">${line}</span>\n`
        }
        ).join('');

        const newCodeBlock = `<pre><div><span class='code-lang-name'>${language}</span><span class='copy-btn' onclick="copyCodeToClipboard(this)">${t("copy_code")}</span></div><hr><code${attrs}>${newCodeLines}</code></pre>`;

        html = html.replace(fullMatch, newCodeBlock);
    });

    return html;
};

// Componente principal MarkdownReader
const MarkdownReader = ({ type, slug, lang }) => {
    const { theme } = useTheme();
    const { t } = useTranslation();
    const [markdown, setMarkdown] = useState('');
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [mod, setMod] = useState('');
    const [categories, setCategories] = useState([]);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');
    const [modalImageTitle, setModalImageTitle] = useState('');

    var fullPath = process.env.PUBLIC_URL + `/md/`
    if (type !== "") {
        fullPath = fullPath + type + "/"
    }
    fullPath = fullPath + lang + "/" + slug + ".md"

    // Fetch del markdown y renderizado inicial
    useEffect(() => {
        fetch(fullPath)
            .then(response => {
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                return response.text();
            })
            .then(text => {
                const { contentWithoutFrontMatter, title, date, mod, categories } = extractFrontMatter(text);
                setTitle(title);
                setDate(date);
                setMod(mod);
                setCategories(categories);

                const renderer = new marked.Renderer();
                renderer.code = (code, lang) => {
                    if (lang === 'mermaid') {
                        return `<div class="mermaid">${code}</div>`;
                    } if (lang === 'math') {
                        return katex.renderToString(code, { displayMode: true });
                    } if (lang === 'plain') {
                        return `<pre><code class="hljs ${lang ? `language-${lang}` : ''}">${code}</code></pre>`;
                    }if (lang === 'plain-no-border') {
                        return `<p style="white-space: pre-wrap;">${code}</p>`;
                    }else {
                        const highlighted = hljs.getLanguage(lang)
                            ? hljs.highlight(code, { language: lang }).value
                            : hljs.highlightAuto(code).value;
                        return `<pre><code class="hljs ${lang ? `language-${lang}` : ''}">${highlighted}</code></pre>`;
                    }
                };

                renderer.text = (text) => {
                    const inlineMathRegex = /\$(.+?)\$/g;
                    const blockMathRegex = /\$\$([\s\S]+?)\$\$/g;

                    text = text.replace(blockMathRegex, (_, expression) => {
                        // Esto no funciona muy bien, mejor usar el bloque de codigo `math`
                        try {
                            return `<div class="math-block">${katex.renderToString(expression, { displayMode: true })}</div>`;
                        } catch (error) {
                            console.error('Error rendering block math:', error);
                            return `<div class="math-error">Error: ${error.message}</div>`;
                        }
                    });

                    text = text.replace(inlineMathRegex, (_, expression) => {
                        try {
                            return `<span class="math-inline">${katex.renderToString(expression)}</span>`;
                        } catch (error) {
                            console.error('Error rendering inline math:', error);
                            return `<span class="math-error">Error: ${error.message}</span>`;
                        }
                    });

                    return text;
                };

                const slugify = (text) => {
                    return text
                        .toString()
                        .toLowerCase()
                        .trim()
                        .replace(/[\s]+/g, '-') // Reemplaza espacios con guiones
                        .replace(/[^\w-]+/g, ''); // Elimina caracteres no válidos
                };

                renderer.heading = (text, level) => {
                    let regex = /{([^}]+)}/;
                    let rid = text.match(regex);
                    if (rid) {
                        rid = rid[1];
                        text = text.replace(regex, "");
                    } else {
                        rid = slugify(text);
                    }

                    return `<h${level} id="${rid}">${text}</h${level}>`;
                };

                marked.setOptions({
                    renderer,
                    highlight: function (code, lang) {
                        return hljs.getLanguage(lang) ? hljs.highlight(code, { language: lang }).value : hljs.highlightAuto(code).value;
                    },
                    langPrefix: 'hljs ',
                    breaks: false, // No convertir saltos de línea en <br>
                    smartypants: false, // No transformar caracteres como & en entidades HTML
                    sanitize: false, // Deshabilitar sanitización de HTML
                });

                setMarkdown(marked(contentWithoutFrontMatter));
            })
            .catch(error => {
                console.error('Error fetching markdown:', error);
                setMarkdown('<p>Error loading post!</p>');
            });
    }, [fullPath]);

    useEffect(() => {
        if (markdown) {
            const markdownElement = document.querySelector('.markdown');
            if (markdownElement) {
                hljs.highlightAll();
                const htmlWithLineNumbers = addLineNumbers(markdownElement.innerHTML, t);
                markdownElement.innerHTML = htmlWithLineNumbers;

                initializeMermaid(theme);
                mermaid.run();
                setTimeout(resizeMermaidGraphs, 300);
                setTimeout(resizeMermaidGraphs, 500);
                setTimeout(resizeMermaidGraphs, 1000);
            }
            const images = document.querySelectorAll('.markdown img');
            images.forEach(img => {
                img.addEventListener('click', () => {
                    setModalImageSrc(img.src);
                    setModalImageTitle(img.title);
                    setIsImageModalOpen(true);
                });

                const spinner = document.createElement('div');
                spinner.className = 'loader';
                img.style.display = 'none';
                img.parentNode.insertBefore(spinner, img);

                img.onload = () => {
                    img.style.display = '';
                    spinner.remove();
                };
            });
        }

        const handleInternalLinkClick = (e) => {
            // Verificar si el clic es en un enlace con hash
            const target = e.target.closest('a'); // Buscar el elemento <a> más cercano
            if (target && target.hash) {
                const targetElement = document.querySelector(target.hash);
                if (targetElement) {
                    e.preventDefault();
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                    // Actualizar el hash en la URL manualmente
                    window.history.pushState(null, '', target.hash);

                    setTimeout(() => {
                        targetElement.classList.add('highlight-blink'); // Añadir clase
                        setTimeout(() => {
                            targetElement.classList.remove('highlight-blink'); // Quitar clase después de 2 segundos
                        }, 900);
                    }, 800); // Retraso para asegurar que el scroll ha terminado

                }
            }
        };
    
        const handleScrollToHash = () => {
            const hash = window.location.hash;
            if (hash) {
                const targetElement = document.querySelector(hash);
                if (targetElement) {
                    setTimeout(() => {
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                        var currentURL = window.location.href;
                        window.history.pushState("", document.title, currentURL.split('#')[0]); 
                    }, 601);

                    setTimeout(() => {
                        targetElement.classList.add('highlight-blink'); // Añadir clase
                        setTimeout(() => {
                            targetElement.classList.remove('highlight-blink'); // Quitar clase después de 2 segundos
                        }, 2000);
                    }, 800); // Retraso para asegurar que el scroll ha terminado
                }
            }
        };
    
        // Ejecutar desplazamiento inicial si hay un hash al cargar la página
        handleScrollToHash();
    
        // Añadir manejadores para eventos de hashchange y enlaces dentro del contenido relevante
        const markdownElement = document.querySelector('.markdown'); // Limitar a tu contenedor
        if (markdownElement) {
            markdownElement.addEventListener('click', handleInternalLinkClick);
        }
        window.addEventListener('hashchange', handleScrollToHash);
    
        // Limpiar eventos al desmontar
        return () => {
            if (markdownElement) {
                markdownElement.removeEventListener('click', handleInternalLinkClick);
            }
            window.removeEventListener('hashchange', handleScrollToHash);
    
        };

    }, [markdown, theme, t]);

    const closeModal = () => {
        setIsImageModalOpen(false);
        setModalImageSrc('');
    };

    window.copyCodeToClipboard = function (button) {
        const codeBlock = button.parentNode.parentNode.querySelector('code');
        const lines = codeBlock.querySelectorAll('.code-line');
        const codeText = Array.from(lines).map(line => line.textContent).join('\n');

        navigator.clipboard.writeText(codeText).then(() => {
            button.textContent = t("copied_code");
            setTimeout(() => {
                button.textContent = t("copy_code");
            }, 2000);
        });
    };

    return (
        <div className={`container container-markdown`}>

            <div className='post-header'>
                {categories.length > 0 ? (
                    <>
                        <p className='categories'>

                            <FaFolderOpen />{" "}
                            {categories.map((category, index) => (
                                <>

                                    <span key={index}>
                                        <a href={`/posts?category=${category}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            {category}
                                        </a>
                                    </span>
                                    {index === categories.length - 2 ? " & " : ""}
                                    {index < categories.length - 2 ? ", " : ""}

                                </>
                            ))}
                        </p>
                    </>
                ) : ""}
                <h1>{title.replace(/'/g, '')}</h1>
                <p class='dates'>{date ? `${t('date_pub')}: ${date}` : ''}<br />{mod ? `${t('date_mod')}: ${mod}` : ''}</p>
            </div>
            <article className='markdown' dangerouslySetInnerHTML={{ __html: markdown }} />
            {isImageModalOpen && (
                <div className="image-modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-btn" onClick={closeModal}>&times;</span>
                        <img src={modalImageSrc} alt="Full screen" />
                        <center><span style={{ color: 'white' }}>{modalImageTitle}</span></center>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MarkdownReader;
