import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import posts_data from '../../content.json';
import MarkdownReader from '../../components/MarkdownReader';
import { useTheme } from '../../themeContext';
import Error404 from './../404/404';

const Post = ({ type, lang }) => {
  const { slug } = useParams();
  const { theme } = useTheme();
  var post = posts_data[type].find(p => p.slug === slug && p.lang === lang);
  if (!post) {
    post = posts_data[type].find(p => p.slug === slug && p.lang === 'global');
    lang = 'global';
  }

  console.log(post);
  useEffect(() => {
    const originalTitle = document.title; // Guarda el título original
    document.title = `M3 | ${slug.replace(/-/g, ' ')}`;

    return () => {
      document.title = originalTitle; // Restaura el título original al desmontar el componente
    };
  }, [slug]);

  if (!post) {
    return <Error404 />;
  }

  return (
    <>
      <div className={`bg ${theme}`}></div>
      <div className={`content ${theme}`}>
        <MarkdownReader type={type} lang={lang} slug={slug} /> 
      </div>
    </>
  );
};

export default Post;
