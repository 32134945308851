import { useEffect } from "react";

const RedirectM = ({ url }) => {
  useEffect(() => {
    window.location.href = url; // Redirige a la URL externa
  }, [url]);

  return null; // No se muestra nada en la pantalla
};

export default RedirectM;
