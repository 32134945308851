import React from 'react';
import { useTheme } from '../../themeContext';
import MarkdownReader from '../../components/MarkdownReader';
import './About.css';

const About = ({ lang }) => {
  const { theme } = useTheme();

  return (
    <>
      {/* Fondo dinámico según el tema */}
      <div className={`bg ${theme}`}></div>

      {/* Contenido principal */}
      <div className={`content ${theme}`}>
        <MarkdownReader type={'misc'} lang={lang} slug={'about'} />
      </div>

      {/* Contenedor de manos detrás del texto */}
      <div className="hands-background">
        <img
          src="media/imgs/left-hand.png"
          alt="Mano izquierda"
          className="hand left-hand"
        />
        <img
          src="media/imgs/right-hand.png"
          alt="Mano derecha"
          className="hand right-hand"
        />
      </div>

    </>
  );
};

export default About;
